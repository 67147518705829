export const isValidName = (name: string): boolean => {
  const nameSplit = name.trim().split(' ')
  const isValidFirstName = nameSplit[0].length >= 1
  const isValidLastName = nameSplit.length > 1 && nameSplit[nameSplit.length - 1].length > 1
  return !!(name && isValidFirstName && isValidLastName && name.length < 26)
}

export function isValidEmail(email: string): boolean {
  if (!email) return false

  const local = email.trim()
  if (local.length === 0) return false

  const split = local.split('@')
  if (split.length < 2) return false

  const [domain] = split.slice(-1)

  return domain.indexOf('.') > -1
}

interface getFormErrorMessageProps {
  name: string
  email: string
}

export const getFormErrorMessage = ({ name, email }: getFormErrorMessageProps): string => {
  if (!isValidName(name) || !isValidEmail(email)) {
    if (!isValidName(name)) return 'Please enter your full name'
    if (!isValidEmail(email)) return 'Please check your email'
  }

  return ''
}
